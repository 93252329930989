import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

export const ArticlePage = (): ReactElement => {
  const { articleSlug } = useParams();

  return (
    <div>
      <h1>Article!</h1>
    </div>
  );
};
