import React from 'react';
import { Button } from '@nextui-org/button';
import { Link } from '@nextui-org/link';
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle } from '@nextui-org/navbar';

import { Logo } from './logo';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <Navbar maxWidth='xl'>
      <NavbarMenuToggle
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        className="md:hidden"
      />
      <NavbarBrand>
        <Link href='/'>
          <Logo className='text-foreground' />
        </Link>
      </NavbarBrand>
      <NavbarContent className='hidden md:flex gap-4' justify='center'>
        <NavbarItem>
          <Link color='foreground' href='#'>
            Aktualności
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color='foreground' href='#'>
            Baza gier
          </Link>
        </NavbarItem>
        <NavbarItem isActive>
          <Link href='#' aria-current='page'>
            Poradniki
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color='foreground' href='#'>
            Recenzje
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color='foreground' href='#'>
            Społeczność
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color='foreground' href='#'>
            Wydarzenia
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color='foreground' href='#'>
            O nas
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color='foreground' href='#'>
            Kontakt
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify='end'>
        <NavbarItem>
          <Button className='font-semibold uppercase' as={Link} href='#' color='primary' radius='full'>
            Dołącz do społecznośći!
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};
