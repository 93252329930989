import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NextUIProvider } from '@nextui-org/system';

import { Layout } from './layout';
import { HomePage } from './pages/home.page';
import { ArticlePage } from './pages/article.page';
import { GamePage } from './pages/game.page';

export const App = (): ReactElement => (
  <NextUIProvider>
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<HomePage />} />
        <Route path='/:articleSlug' element={<ArticlePage />} />
        <Route path='/:platformSlug/:gameSlug' element={<GamePage />} />
      </Route>
    </Routes>
  </NextUIProvider>
);
