import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from './components/footer';
import { Header } from './components/header';

export const Layout = (): ReactElement => {
  return (
    <>
      <Header />
      <main className='flex-grow'>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
