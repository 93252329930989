import React from 'react';
interface PropsInterface {
  width?: number;
  height?: number;
  className?: string;
}

export const Logo = ({ width = 138, height = 32, className }: PropsInterface) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 138 32'
      shapeRendering='optimizeQuality'
      fill='currentColor'
      role='img'
      aria-label='Goomba: Polska społeczność fanów Nintendo'
      width={width}
      height={height}
      className={className}
    >
      <path d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16h4a8 8 0 0 0 6.92-4h-.004A8 8 0 0 0 28 24v-8a8 8 0 1 0-8 8 8 8 0 0 0 4-1.08V24a4 4 0 0 1-4 4h-4C9.373 28 4 22.627 4 16S9.373 4 16 4h72.7a12 12 0 0 1 2.374-4zm84 0a8 8 0 0 0-6.92 4h.004A8 8 0 0 0 92 8v8a8 8 0 1 0 8-8 8 8 0 0 0-4 1.08V8a4 4 0 0 1 4-4h22c6.627 0 12 5.373 12 12s-5.373 12-12 12H31.3a12 12 0 0 1-2.374 4H122c8.837 0 16-7.163 16-16s-7.163-16-16-16ZM20 12a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm80 0a4 4 0 1 1 0 8 4 4 0 0 1 0-8z' />
      <path d='M38 8a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8zm0 4a4 4 0 0 1 4 4 4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4zM56 8a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8Zm0 4a4 4 0 0 1 4 4 4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4zM118 8a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 4.174-1.186A2 2 0 0 0 124 24a2 2 0 0 0 2-2v-6a8 8 0 0 0-8-8zm0 4a4 4 0 0 1 4 4 4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4zM73 7a7 7 0 0 0-7 7v8a2 2 0 0 0 2 2 2 2 0 0 0 2-2v-8a3 3 0 0 1 3-3 3 3 0 0 1 3 3v8a2 2 0 0 0 2 2 2 2 0 0 0 2-2v-8a3 3 0 0 1 3-3 3 3 0 0 1 3 3v8a2 2 0 0 0 2 2 2 2 0 0 0 2-2v-8a7 7 0 0 0-7-7 7 7 0 0 0-5.004 2.107A7 7 0 0 0 73 7z' />
    </svg>
  );
};
