import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

export const GamePage = (): ReactElement => {
  const { platformSlug, gameSlug } = useParams();

  return (
    <div>
      <h1>Game!</h1>
    </div>
  );
};
