import React from 'react';
import { Link } from '@nextui-org/link';

import { Logo } from './logo';

export const Footer = () => {
  return (
    <footer className='flex items-center justify-center'>
      <div className='flex justify-center w-full max-w-screen-xl px-6 py-4 gap-4'>
        <Link href='/' className='opacity-80 hover:opacity-100'>
          <Logo height={24} className='text-foreground' />
        </Link>
      </div>
    </footer>
  );
};
